import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/Layouts/Base"
import Sidebar from "../../components/Integrations/Sidebar"

import listImage from "../../images/integrations/wix/latest/list.png"
import buttonMenuImage from "../../images/integrations/wix/latest/button-menu.png"
import linkMenuImage from "../../images/integrations/wix/latest/link-menu.png"
import paymentPlanImage from "../../images/integrations/wix/latest/payment-plan.png"
import img1 from "../../images/integrations/wix/1.png"
import Player from "../../components/VideoPopup/Player"

export const query = graphql`
  query WixIntegrationQuery {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const IntegrationWebStartsPage = ({ data }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Accept Payments & Subscriptions using Wix with Payhere"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Wix subscription payments using Payhere
            </h1>

            <div className="mt-8">
              <Player youtubeId={"ocOy9ZlUiak"}></Player>
            </div>

            <div className="prose mt-6">
              <p>
                Here is a really simple way that you can insert one-off,
                subscription payments, or donation based payment plans into your
                Wix website.
              </p>
              <h2>1. Create your subscription payment plan (within Payhere)</h2>
              <p>
                Within our plan builder you can choose if you want a weekly,
                monthly or yearly recurring payment plan. Enter the rest of your
                details such as name, amount etc and then save the plan.
              </p>

              <img
                src={img1}
                className="Creating a Wix subscription payment with Payhere"
              />

              <h2>
                2. Add a list of available services with buttons to your wix
                website
              </h2>

              <p>
                Once you have your payment links created in Payhere and the
                links safely copied, you can add a button or list of services to
                your wix website. In the below example, I've pressed the{" "}
                <strong>Add elements</strong> button on the left hand side of
                the wix editor, chosen the <strong>List</strong> category and
                selected a list of 4 items, with images and buttons that we can
                use to link to the payment links.
              </p>

              <img
                src={listImage}
                className="Add a list of subscription payment services to your Wix website"
              />

              <h2>3. Link your Wix buttons to your Payhere payment links</h2>

              <p>
                With your list added, simply click on your button, and you
                should see a tooltip for <strong>Change Text &amp; Icon</strong>
                .{" "}
              </p>
              <p>
                Clicking that will open a menu that allows you to change the
                button text, and also choose where to link the button.
              </p>
              <img
                src={buttonMenuImage}
                alt="Edit button text and icon"
                className=""
              />
              <p>
                Select <strong>Web address</strong> from the left hand menu, and
                paste in the link to your payment link, and you can choose
                whether you want to open this in the current browser window, or
                in a new window. If unsure just leave it selected on{" "}
                <strong>New window</strong> and press <strong>Done</strong>
              </p>
              <img
                src={linkMenuImage}
                alt="Link to a web address on Wix"
                className=""
              />

              <p>
                And that’s it! The listed services on your Wix site are now
                connected to Payhere and you can start recieving recurring
                payments.
              </p>

              <h2>Why use Payhere rather than Wix built in payments?</h2>

              <p>
                Wix offers built-in payments and subscription payments for an
                additional monthly fee. However, if you choose to use Payhere,
                you can create more advanced types of payments, such as Payment
                plans (read more below), and we don’t lock you or your customers
                into a specific website builder, should you choose to change
                your website in the future.
              </p>
              <p>
                Using Payhere for your Wix recurring payments allows you to
                scale and grow your payments as your business grows.
              </p>

              <h2>Wix Payment plans</h2>

              <p>
                If you want to spread the payments for your customers, you can
                create a payment plan in Payhere.{" "}
              </p>

              <ol>
                <li>Create your subscription as normal within Payhere</li>
                <li>Click on the billing tab</li>
                <li>
                  Enter a value (in months) into the{" "}
                  <em>Cancel subscription after</em> box
                </li>
                <li>
                  Follow the other steps above to link to your payment plan from
                  your Wix website
                </li>
                <li>That’s it 🎉</li>
              </ol>

              <img src={paymentPlanImage} alt="Wix payment plans" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IntegrationWebStartsPage
