import React from "react"
import YouTube from "@u-wave/react-youtube"
import styles from "./VideoPopup.module.css"

const Player = ({ youtubeId, autoplay }) => {
  return (
    <div className={styles.embedContainer}>
      <YouTube
        video={youtubeId}
        autoplay={autoplay}
        className={styles.embedPlayer}
      />
    </div>
  )
}

export default Player
